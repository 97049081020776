import React, { Fragment } from 'react';
import './App.scss'

const Header = React.lazy(() => import("components/Header"));
const Menu = React.lazy(() => import("components/Menu"));
const Services = React.lazy(() => import("components/Services"));
const Platforms = React.lazy(() => import("components/Platforms"));
const Clients = React.lazy(() => import("components/Clients"));
const Donations = React.lazy(() => import("components/Donations"));
const Contact = React.lazy(() => import("components/Contact"));
const Footer = React.lazy(() => import("components/Footer"));

class App extends React.Component {
  listener = null;
  state = {
    width: window.innerWidth,
    breakpoint: 768
  }
  componentDidMount() {
    const links = document.querySelectorAll(".menu ul a");
    links.forEach(node =>  { 
      node.addEventListener("click", this.clickHandler);
    })
    
  }
  
  clickHandler(e) {
    e.preventDefault();
    const href = e.getAttribute("href");
    document.querySelector(href).scrollIntoView({
      behavior: "smooth"
    });
  }


  render() {
    return  (
      <Fragment>
        <div className='container'>
          <header className="header">
              <Header />
          </header>
          {this.state.width <= this.state.breakpoint && 
                <Menu />}
          <main>
            <section  className="home" id="services">
              <h1>Development and consulting e-commerce and digital</h1>
            </section>
            <section  className="services">
              <Services />
            </section>
            <section  className="platforms">
              <Platforms />
            </section>
            <section  className="clients" id="clients">
              <Clients />
            </section>
            <section  className="contact" id="contact">
              <Contact />
            </section>
            <section  className="donations" id="donations">
              <Donations />
            </section>
          </main>
          <footer>
              <Footer />
          </footer>
        </div>
        
      </Fragment>
    );
  }
}
export default App;